<template>
  <div>
    <VButton
      class="flex items-center w-full h-full gap-2"
      :size="Size.sm"
      :variant="Variant.secondaryDark"
      @click="handlePhoneClick"
    >
      <PhoneIcon
        class="w-6 h-6 p-0.5 md:w-4 md:h-4 md:p-0"
        aria-hidden="true"
      />

      <span :class="computedTextClasses">
        {{ $t('webPhoneFormatted') }}
      </span>
    </VButton>
  </div>
</template>

<script setup lang="ts">
import { Size, Variant } from '@/types/enums'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'

const props = withDefaults(
  defineProps<{
    isTextDisplayedMobile?: boolean
  }>(),
  {
    isTextDisplayedMobile: false,
  }
)

const {
  public: { CONTACT_PHONE },
} = useRuntimeConfig()

function handlePhoneClick() {
  window.location.href = `tel:${CONTACT_PHONE}`
}

const computedTextClasses = computed(() => {
  return props.isTextDisplayedMobile ? 'block' : 'sr-only md:block'
})
</script>
